import React, { useState } from 'react';
import './MatchDetails.css';
import { useParams } from 'react-router-dom';

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';

function MatchDetails() {
  let { id } = useParams();
  const [match, setMatch] = useState<any>(null);

  if(match == null) {
    fetch(`/api/match/${id}`).then((response) => response.json()).then((match) =>
    { 
      setMatch(match);
    })
  }

  return (
    <div className="MatchDetails">
      <MediaPlayer src={`https://cdn.starium.tv/${id}.mp4`}>
        <MediaProvider />
        <DefaultVideoLayout icons={defaultLayoutIcons} />
    </MediaPlayer>
    <h1>{match?.name}</h1>
    </div>
  );
}

export default MatchDetails;
