import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import MatchDetails from './routes/match/MatchDetails';

function App() {
  return (
    <div className="App">
      <header>
        <img className='logo' src="/logo192.png" /> <span></span>
      </header>
      <Routes>
        <Route path="/">
          <Route path="match/:id" element={<MatchDetails />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
